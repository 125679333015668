var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "header" } }, [
    _c("a", { staticClass: "logo", attrs: { href: _vm.homeUrl } }, [
      _vm.appLogo ? _c("img", { attrs: { src: _vm.appLogo } }) : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "right" },
      [
        _vm.showDownload
          ? _c(
              "a",
              {
                staticClass: "ui button primary icon download",
                attrs: { href: _vm.downloadUrl, title: "Download" },
                on: { click: _vm.handleDownload },
              },
              [
                _c("i", {
                  staticClass: "icon download",
                  class: { hidden: !_vm.showDownloadIcon },
                }),
                _c("span", { class: { "mobile hide": _vm.showDownloadIcon } }, [
                  _vm._v(" " + _vm._s(_vm.downloadLabel)),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.storageInfoDialogOpen
          ? _c(
              "Alert",
              {
                attrs: { title: "Storage Info" },
                on: { onClose: _vm.handleStorageInfoDialogClose },
              },
              [
                _c("div", [
                  _vm._v(
                    "\n                Used " +
                      _vm._s(_vm._f("bytes")(_vm.storageInfo.used)) +
                      " on " +
                      _vm._s(_vm._f("bytes")(_vm.storageInfo.total)) +
                      " total\n                "
                  ),
                  _vm.storageInfo.free > 0
                    ? _c("span", [
                        _vm._v(
                          ", " +
                            _vm._s(_vm._f("bytes")(_vm.storageInfo.free)) +
                            " free"
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.storageInfo.free <= 0
                    ? _c("span", [
                        _c("br"),
                        _c("b", [_vm._v("No storage left!")]),
                      ])
                    : _vm._e(),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.showSettings
          ? _c(
              "a",
              {
                staticClass: "ui circular button default icon settings",
                attrs: { href: "javascript:void(0)", title: "Settings" },
                on: { click: _vm.handleSettings },
              },
              [_c("i", { staticClass: "icon wrench" })]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.loggedIn
          ? _c(
              "button",
              {
                staticClass: "ui button primary icon",
                on: { click: _vm.login },
              },
              [
                _c("i", { staticClass: "icon lock" }),
                _c("span", { staticClass: "mobile hide" }, [
                  _vm._v(" Sign In"),
                ]),
              ]
            )
          : _c(
              "div",
              {
                staticClass:
                  "circular ui icon top right pointing dropdown button user-menu",
                attrs: { title: _vm.username },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.toggleMenu.apply(null, arguments)
                  },
                },
              },
              [
                _c("i", { staticClass: "icon user" }),
                _vm._v(" "),
                _c("div", { ref: "menu", staticClass: "menu" }, [
                  _c("div", { staticClass: "header" }, [
                    _vm._v(_vm._s(_vm.username) + " "),
                    _vm.isAdmin && _vm.username != "admin"
                      ? _c("span", [_vm._v(" — "), _c("i", [_vm._v("admin")])])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "divider" }),
                  _vm._v(" "),
                  _vm.fileUploads
                    ? _c(
                        "div",
                        { staticClass: "item", on: { click: _vm.uploadFiles } },
                        [
                          _c("i", { staticClass: "icon cloud upload" }),
                          _vm._v(" Upload Files"),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "item", on: { click: _vm.myOrganizations } },
                    [
                      _c("i", { staticClass: "icon sitemap" }),
                      _vm._v(" My Organizations"),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "item", on: { click: _vm.myDatasets } },
                    [
                      _c("i", { staticClass: "icon database" }),
                      _vm._v(" My Datasets"),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.storageInfo
                    ? _c("div", { staticClass: "divider only" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.storageInfo && _vm.storageInfo.total != null
                    ? _c(
                        "div",
                        {
                          staticClass: "item only",
                          on: {
                            click: function ($event) {
                              _vm.storageInfoDialogOpen = true
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "icon hdd outline" }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("percent")(
                                  _vm.storageInfo.usedPercentage,
                                  2
                                )
                              )
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.storageInfo && _vm.storageInfo.total == null
                    ? _c("div", { staticClass: "item only" }, [
                        _c("i", { staticClass: "icon hdd outline" }),
                        _vm._v(
                          " " + _vm._s(_vm._f("bytes")(_vm.storageInfo.used))
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.accountManagement && _vm.loggedIn) ||
                  (_vm.usersManagement && _vm.isAdmin)
                    ? _c("div", { staticClass: "divider" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.accountManagement && _vm.loggedIn
                    ? _c(
                        "div",
                        {
                          staticClass: "item only",
                          on: { click: _vm.manageAccount },
                        },
                        [
                          _c("i", { staticClass: "icon user" }),
                          _vm._v(" My Account"),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.usersManagement && _vm.isAdmin
                    ? _c(
                        "div",
                        {
                          staticClass: "item only",
                          on: { click: _vm.manageUsers },
                        },
                        [
                          _c("i", { staticClass: "icon users" }),
                          _vm._v(" Manage Users"),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "divider" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "item", on: { click: _vm.logout } },
                    [
                      _c("i", { staticClass: "icon sign-out" }),
                      _vm._v(" Logout"),
                    ]
                  ),
                ]),
              ]
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }